<template>
  <v-card
    flat
    class="iteration rounded-10 border-2"
    :class="[isValid ? 'border-color--primary' : 'border-color--error']"
  >
    <v-card-title
      @click="show = !show"
      :class="[isValid ? 'primary--text' : 'error--text']"
      class="d-flex align-center py-2 cursor-pointer justify-space-between"
    >
      <span class="fs-16 fw-700">Iteration {{ iteration.step }}</span>
      <span class="fw-500 fs-13">{{ normalize_range }}</span>
      <div>
        <HelpToolTip
          icon-color="primary"
          @click:icon="$emit('delete')"
          icon="mdi-delete"
          text="Delete Iteration"
        ></HelpToolTip>
        <v-icon color="primary">
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </v-card-title>
    <v-expand-transition>
      <v-card-text v-show="show">
        <v-row align="center" justify="start">
          <v-col cols="12" md="12" sm="12">
            <label class="subtitle-2 primary--text"> Content Type : </label>
            <v-radio-group
              class="mx-auto mt-1 text-center"
              v-model="iteration.content_type"
              row
              dense
              color="primary"
              hide-details="auto"
            >
              <v-radio value="template">
                <template v-slot:label>
                  <span class="fw-700 primary--text">Use HTML Template</span>
                </template>
              </v-radio>
              <v-radio value="plain">
                <template v-slot:label>
                  <span class="fw-700 primary--text">Plain Text</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col
            cols="12"
            md="12"
            v-show="iteration.content_type === 'template'"
          >
            <label class="subtitle-2 primary--text">
              Select template for this iteration
            </label>
            <div
              class="d-flex align-center justify-space-between"
              :class="{ 'flex-wrap': !mdAndUp }"
            >
              <v-select
                dense
                outlined
                :rules="[requiredRule()]"
                clear-icon="mdi-close-circle-outline"
                placeholder="Required"
                hide-details="auto"
                small-chips
                :items="templates"
                item-text="name"
                item-value="id"
                deletable-chips
                chips
                no-data-text="No template yet"
                clearable
                v-model="iteration.template"
                class="ma-1"
              >
              </v-select>
              <v-btn
                @click.stop="createNewTemplate"
                depressed
                outlined
                :block="!mdAndUp"
                height="40"
                class="text-none rounded-10 ma-1 caption"
                color="primary"
              >
                <v-icon left color="primary">mdi-plus</v-icon>
                <span class="fw-700"> New Template</span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="12" v-show="iteration.content_type === 'plain'">
            <label class="subtitle-2 primary--text">
              Enter email contents for this iteration
            </label>
            <v-textarea
              clearable
              placeholder="Required, Enter text"
              auto-grow
              hide-details="auto"
              :rows="3"
              :rules="[requiredRule()]"
              outlined
              class="mb-2"
              v-model="iteration.content"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="start">
          <v-col cols="12" md="12" sm="12">
            <label class="subtitle-2 primary--text"> Schedule </label>
            <span class="caption ml-2">
              (This iteration will be sent one time during this period)
            </span>
            <v-sheet
              class="fullwidth d-flex align-center justify-center"
              style="overflow-y: auto"
            >
              <DateRangePicker
                class="mt-2 mx-auto"
                :min-date="today"
                opens="inline"
                :locale-data="locale_data"
                :date-range="iteration.schedule"
                :show-week-numbers="false"
                :ranges="false"
                :time-picker="false"
                :show-dropdowns="false"
                auto-apply
                @update="handleUpdate($event)"
              >
                <template v-slot:input="picker" style="min-width: 300px">
                  <span class="primary--text fw-700" v-if="picker.startDate">
                    {{ normalize_range_by(picker.startDate, picker.endDate) }}
                  </span>
                  <span class="error--text fw-700" v-else>
                    Select start date and end date from below
                  </span>
                </template>
              </DateRangePicker>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>
    <!-- {{ iteration }} -->
  </v-card>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
  name: 'SingleTemplateIteration',
  components: {
    DateRangePicker
  },
  props: {
    value: { type: Object }
  },
  computed: {
    ...mapGetters('campaigns', ['templates']),
    today() {
      return this.moment(new Date()).format('YYYY-MM-DD')
    },
    isValid() {
      if (!this.iteration) return false
      let hasContent =
        this.iteration.content_type === 'plain'
          ? this.iteration.content
            ? true
            : false
          : this.iteration.template
          ? true
          : false
      return this.iteration.schedule.startDate &&
        this.iteration.schedule.endDate &&
        hasContent
        ? true
        : false
    },
    normalize_range() {
      if (
        !this.iteration.schedule.startDate ||
        !this.iteration.schedule.endDate
      )
        return `No dates selected`
      return `Around ${this.moment(this.iteration.schedule.startDate).format(
        'll'
      )} to ${this.moment(this.iteration.schedule.endDate).format('ll')}`
    }
  },
  data: () => ({
    show: true,
    isEditting: false,
    iteration: {
      step: 1,
      content_type: 'template',
      content: null,
      template: null,
      schedule: {
        startDate: null,
        endDate: null
      }
    },
    locale_data: {
      firstDay: 1,
      format: 'yyyy-mm-dd'
    }
    // content: null,
    // content_type: 'plain',
    // selectedtemplate: null
  }),
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.iteration = val
          // this.$nextTick(() => {
          //   this.selectedtemplate = this.iteration.template || null
          //   this.content_type = this.iteration.content_type || 'plain'
          //   this.content = this.iteration.message || null
          // })
        }
      },
      immediate: true,
      deep: true
    },
    iteration: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
    // content: {
    //   handler: function (val) {
    //     this.$set(this.iteration, 'content', val)
    //   },
    //   immediate: true
    // },
    // content_type: {
    //   handler: function (val) {
    //     this.$set(this.iteration, 'content_type', val)
    //     if (val === 'plain') {
    //       this.selectedtemplate = null
    //       this.$set(this.iteration, 'template', null)
    //     }
    //     if (val !== 'plain') {
    //       this.content = null
    //       this.$set(this.iteration, 'content', null)
    //     }
    //   },
    //   immediate: true
    // },
    // selectedtemplate: {
    //   handler: function (val) {
    //     this.$set(this.iteration, 'template', val)
    //   },
    //   immediate: true
    // }
  },
  methods: {
    handleUpdate(event) {
      this.$set(this.iteration, 'schedule', {
        startDate: this.moment(event.startDate).format('YYYY-MM-DD'),
        endDate: this.moment(event.endDate).format('YYYY-MM-DD')
      })
    },
    createNewTemplate() {
      this.$router
        .push({
          name: 'builders-email--template-campaign',
          query: { redirect_uri: this.$route.fullPath }
        })
        .catch(() => {})
    },
    normalize_range_by(from, to) {
      if (!from || !to) return `No dates selected`
      return `Around ${this.moment(from).format('LL')} to ${this.moment(
        to
      ).format('LL')}`
    }
  }
}
</script>

<style lang="css">
.iteration .daterangepicker td.in-range {
  background-color: #223a6b;
  color: #fbf7f7;
}
</style>
