<template>
  <v-card elevation="3" class="rounded-10 campaign-creator" min-height="100vh">
    <v-card-title
      class="primary--text d-flex align-center justify-space-between fw-700"
    >
      <span class="ml-2">{{ title }}</span>
      <v-btn @click="goBack" icon>
        <v-icon large color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-row dense align="stretch" justify="space-between">
          <v-col cols="12" md="9" sm="12">
            <v-stepper
              v-model="progress"
              light
              flat
              class="border-color--grey border-1"
            >
              <v-stepper-header>
                <v-stepper-step
                  :rules="[step1Rules]"
                  :complete="progress > 1"
                  :step="1"
                  class="fw-700"
                >
                  Settings
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :rules="[step2Rules]"
                  :complete="progress > 2"
                  :step="2"
                  class="fw-700"
                >
                  Content & Schedule
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :rules="[step3Rules]" :step="3" class="fw-700">
                  Review & {{ isEdit ? 'Update' : 'Save' }}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <label class="subtitle-2 primary--text">
                        Campaign Name (Required)
                      </label>
                      <v-text-field
                        outlined
                        dense
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        v-model="mapping.name"
                        hide-details="auto"
                        placeholder="Required"
                        :rules="[requiredRule()]"
                        class="mb-2"
                      ></v-text-field>
                      <label class="subtitle-2 primary--text">
                        Campaign Subject (Required)
                      </label>
                      <v-text-field
                        outlined
                        dense
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        v-model="mapping.subject"
                        hide-details="auto"
                        placeholder="Required"
                        :rules="[requiredRule()]"
                        class="mb-2"
                      ></v-text-field>
                      <v-row dense>
                        <v-col cols="12" md="12" sm="12">
                          <label class="subtitle-2 primary--text">
                            Group Recipients
                            <span class="fw-500 fs-11">
                              (Optional if Individual Recipients is provided)
                            </span>
                          </label>
                          <v-select
                            dense
                            outlined
                            :rules="[requiredRule()]"
                            clear-icon="mdi-close-circle-outline"
                            placeholder="Optional"
                            hide-details="auto"
                            multiple
                            small-chips
                            :items="lead_groups"
                            item-text="name"
                            item-value="id"
                            deletable-chips
                            chips
                            clearable
                            v-model="mapping.groups"
                            class="mb-2"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label class="subtitle-2 primary--text">
                            Individual Recipients
                            <span class="fw-500 fs-11">
                              (Optional if Group Recipients is provided)
                            </span>
                          </label>
                          <v-autocomplete
                            v-model="mapping.leads"
                            :items="search_items"
                            :loading="isLoading"
                            :search-input.sync="search_word"
                            chips
                            deletable-chips
                            multiple
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            hide-details="auto"
                            item-text="fullname"
                            item-value="id"
                            placeholder="Required"
                            :rules="[requiredRule()]"
                            outlined
                            dense
                            cache-items
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  Search for
                                  <strong>leads</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template
                              v-slot:selection="{
                                attr,
                                on,
                                item,
                                index,
                                selected
                              }"
                            >
                              <v-chip
                                v-bind="attr"
                                :input-value="selected"
                                label
                                small
                                outlined
                                clearable
                                v-on="on"
                                v-if="index < 2"
                              >
                                <span v-text="item.fullname"></span>
                              </v-chip>
                              <v-chip
                                v-bind="attr"
                                :input-value="selected"
                                label
                                small
                                outlined
                                clearable
                                v-on="on"
                                v-if="index === 2"
                              >
                                <span>
                                  + {{ mapping.leads.length - 2 }} others
                                </span>
                              </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content :key="item.id">
                                <v-list-item-title
                                  v-text="item.fullname"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-text="
                                    item.email || item.contacts.input || ''
                                  "
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row dense align="center">
                        <v-col cols="12" sm="12" md="6">
                          <label class="subtitle-2 primary--text">
                            Using Mailer
                          </label>
                          <v-select
                            :items="mailers"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            v-model="mapping.mailer_id"
                            :rules="[requiredRule()]"
                            hide-details="auto"
                            class="mb-2"
                            placeholder="Required"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <label class="subtitle-2 primary--text">
                            Email Signature (Optional)
                          </label>
                          <v-autocomplete
                            no-data-text="No signature yet"
                            :items="signatures"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            deletable-chips
                            chips
                            small-chips
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            v-model="mapping.email_signature"
                            hide-details="auto"
                            placeholder="Optional"
                            append-outer-icon="mdi-plus-circle-outline"
                            @click:append-outer="addSignature"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label class="subtitle-2 primary--text">
                            Cc (Optional)
                          </label>
                          <v-text-field
                            outlined
                            dense
                            clearable
                            :rules="multipleEmailRules"
                            clear-icon="mdi-close-circle-outline"
                            v-model="mapping.cc"
                            hide-details="auto"
                            class="mb-2"
                            placeholder="Optional, Separated by comma"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content :step="2" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <SingleEmailIteration
                        v-for="(iteration, index) in mapping.iteration_items"
                        :key="index"
                        class="mb-1"
                        :value="mapping.iteration_items[index]"
                        @delete="handleDelete(index)"
                        @input="handleUpdate($event, index)"
                      ></SingleEmailIteration>
                      <v-btn
                        block
                        :disabled="is_max_iterations"
                        outlined
                        @click="addNewIteration"
                        class="text-none fw-900 border-dashed mx-1 my-3"
                        color="primary"
                      >
                        <v-icon left>mdi-plus</v-icon> New Iteration
                        <span v-if="is_max_iterations">
                          (Max of {{ max_iteration }})
                        </span>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content :step="3" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <v-sheet class="mb-1 rounded-10 fullwidth">
                        <v-row
                          dense
                          class="border-1 pa-3 rounded-tl-10 rounded-tr-10"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Campaign Name :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{
                              mapping.name ||
                              'Not set. This is a required field!'
                            }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Group Recipients :
                          </v-col>
                          <v-col
                            class="primary--text fw-900 d-flex align-center flex-wrap justify-start"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            <v-chip
                              small
                              class="mr-1"
                              outlined
                              color="primary"
                              v-for="group in groups_selected"
                              :key="group.id"
                            >
                              {{ group.name | ucwords }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Individual Recipients :
                          </v-col>
                          <v-col
                            class="primary--text fw-900 d-flex align-center flex-wrap justify-start"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            <v-chip
                              small
                              class="mr-1"
                              outlined
                              color="primary"
                              v-for="lead in leads_selected"
                              :key="lead.id"
                            >
                              {{ lead.fullname | ucwords }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Subject :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{
                              mapping.subject ||
                              'Not set. This is a required field!'
                            }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Use email for CC :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{ mapping.cc || 'None' }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3 rounded-bl-10 rounded-br-10"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Number of Iteration :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{
                              mapping.iteration_items.length ||
                              'No iteration found. This is a required field!'
                            }}
                          </v-col>
                        </v-row>
                      </v-sheet>
                      <v-sheet class="mb-1 rounded-10 fullwidth">
                        <v-window class="my-2" v-model="slider" show-arrows>
                          <v-window-item
                            v-for="iteration of mapping.iteration_items"
                            :key="iteration.step"
                          >
                            <v-card
                              flat
                              outlined
                              class="fullwidth d-flex flex-column"
                              min-height="200"
                            >
                              <v-card-title
                                class="primary fs-15 py-1 light--text"
                              >
                                <strong>Iteration : </strong>
                                <strong class="ml-1">
                                  {{ iteration.step }}
                                </strong>
                              </v-card-title>
                              <v-card-text class="py-4">
                                <v-row dense align="center">
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Content Type
                                    </strong>
                                    <span class="primary--text">
                                      {{ iteration.content_type | ucwords }}
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Template
                                    </strong>
                                    <span
                                      class="primary--text"
                                      v-if="
                                        iteration.content_type === 'template'
                                      "
                                    >
                                      {{ templateById(iteration.template) }}
                                    </span>
                                    <span class="primary--text" v-else>
                                      Using plain text
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Schedule
                                    </strong>
                                    <v-chip
                                      outlined
                                      color="primary"
                                      class="mx-1"
                                    >
                                      {{
                                        iteration.schedule.startDate
                                          | format('LL')
                                      }}
                                      to
                                      {{
                                        iteration.schedule.endDate
                                          | format('LL')
                                      }}
                                    </v-chip>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Content
                                    </strong>
                                    <div class="primary--text pa-2">
                                      <span
                                        v-if="
                                          iteration.content_type === 'plain'
                                        "
                                      >
                                        {{
                                          iteration.content
                                            | trunc_mid(200, 200)
                                        }}
                                      </span>
                                      <span v-else> Using HTML template </span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-window-item>
                        </v-window>
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <v-row dense class="my-2 pa-2" align="center" justify="center">
              <v-btn
                min-width="150"
                v-if="progress > 1"
                color="primary"
                class="text-none mx-2"
                @click="progress--"
              >
                Back
              </v-btn>
              <v-btn
                min-width="150"
                v-if="progress < 3"
                color="primary"
                class="text-none mx-2"
                :disabled="!can_next"
                @click="progress += 1"
              >
                Next
              </v-btn>
              <v-btn
                min-width="120"
                v-if="progress > 2"
                :loading="btnloading"
                color="primary"
                class="text-none"
                :disabled="!can_submit"
                @click="save"
              >
                <v-icon left color="white">save</v-icon>
                {{ isEdit ? 'Update' : 'Save' }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" v-if="mdAndUp">
            <v-sheet
              color="#8092b8"
              outlined
              class="d-flex ocerflow-hidden align-end flex-column justify-end rounded-5 pb-2"
              height="100%"
            >
              <v-img
                :lazy-src="settings.loader"
                contain
                max-height="250"
                :src="side_image"
              ></v-img>
            </v-sheet>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <DeleteDialog
      v-model="confirm_edit_campaign"
      title="Updating Campaign"
      text-content="Are you sure you want to update this campaign?<br> This will remove any previous iterations and pending emails generated for this campaign."
      @delete="proceedUpdate"
      delete-button-text="Yes! Update Campaign"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import { fetch_services } from '@/services/fetch/fetch_services'
import SingleEmailIteration from '../components/SingleEmailIteration.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: { DeleteDialog, SingleEmailIteration },
  mixins: [fetch_services],
  data: () => ({
    slider: null,
    side_image: require('@/assets/temp/pngwing-email.png'),
    confirm_edit_campaign: false,
    progress: 1,
    max_iteration: 10,
    preview_step: 0,
    mapping: {
      id: null,
      name: null,
      attachments: [], //id of attachment table
      subject: null,
      cc: null,
      mailer_id: null,
      email_signature: null,
      groups: [], //id of lead_groups
      leads: [], //id of leads
      iteration_items: []
    },
    valid: false,
    placeholders: [
      'target_first_name',
      'target_last_name',
      'target_fullname',
      'target_organization',
      'target_email',
      'from_website',
      'from_organization'
    ],
    btnloading: false,
    search_items: [],
    isLoading: false,
    search_word: null
  }),
  watch: {
    '$route.params.id': {
      handler: function (val) {
        if (val)
          this.fetchCampaign({
            id: val,
            cb: (data) => this.mapItem()
          })
        else this.set_campaign(null)
      },
      immediate: true
    },
    'mapping.iteration_items': {
      handler: function (val) {
        if (!this.isEdit && (!val || !val.length)) this.addNewIteration()
      },
      immediate: true
    },
    search_word: _debounce(function (val) {
      this.search(val)
    }, 500)
  },
  computed: {
    ...mapGetters('campaigns', [
      'mailers',
      'signatures',
      'campaign',
      'templates',
      'lead_groups'
    ]),
    is_max_iterations() {
      return this.mapping.iteration_items.length >= this.max_iteration
    },
    isEdit() {
      return !!this.campaign
    },
    title() {
      return this.isEdit
        ? `Edit Email Campaign (${this.campaign.name})`
        : 'Create New Email Campaign'
    },
    today() {
      return this.moment(new Date()).format('YYYY-MM-DD')
    },
    can_proceed_2() {
      return this.step1Rules()
    },
    can_proceed_3() {
      return this.step2Rules()
    },
    can_submit() {
      return this.step3Rules()
    },
    can_next() {
      if (this.progress === 1) return this.can_proceed_2
      else if (this.progress === 2) return this.can_proceed_3
      else return false
    },
    payload() {
      return {
        name: this.mapping.name,
        subject: this.mapping.subject,
        cc: this.mapping.cc,
        mailer_id: this.mapping.mailer_id,
        email_signature: this.mapping.email_signature,
        groups: this.mapping.groups,
        leads: this.mapping.leads,
        iterations: this.mapping.iteration_items
      }
    },
    groups_selected() {
      return this.lead_groups.filter((i) => this.mapping.groups.includes(i.id))
    },
    leads_selected() {
      return this.search_items.filter((i) => this.mapping.leads.includes(i.id))
    }
  },
  created() {
    this.fetchEmailSignatures()
    this.fetchEmailTemplates()
    this.getGroups()
    this.fetchMailers()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaign',
      'fetchEmailSignatures',
      'fetchEmailTemplates',
      'getGroups',
      'fetchMailers'
    ]),
    ...mapMutations('campaigns', ['set_campaign']),
    save() {
      if (!this.isEdit) {
        this.btnloading = true
        this.handleSaveEmailCampaign(this.payload)
      } else {
        this.confirm_edit_campaign = true
      }
    },
    handleSaveEmailCampaign(payload) {
      this.addItem(
        `api/lead-campaign/email`,
        payload,
        'New email campaign added!',
        () => {
          this.$router.push({ name: 'campaigns', params: { type: 'email' } })
        },
        null
      )
    },
    search(keyword) {
      if (
        !this.isLoading &&
        keyword &&
        (keyword !== 'undefined' || typeof keyword === 'undefined')
      ) {
        this.isLoading = true
        request
          .get(`api/leads?search=${keyword}&per_page=10`)
          .then(({ data }) => {
            if (data.data) this.search_items = data.data
          })
          .finally(() => (this.isLoading = false))
      }
    },
    proceedUpdate() {
      this.btnloading = true
      this.confirm_edit_campaign = false
      this.updateItem(
        `api/lead-campaign/email/${this.campaign.id}`,
        this.payload,
        'Campaign updated',
        () => {
          this.$router.push({ name: 'campaigns', params: { type: 'email' } })
          this.btnloading = false
        },
        null
      )
    },
    mapItem() {
      let item = _cloneDeep(this.campaign)
      this.mapping.id = item.id
      this.mapping.name = item.name
      this.mapping.attachments = []
      this.mapping.subject = item.props.subject
      this.mapping.cc = item.props.cc
      this.mapping.mailer_id = item.mailer_id
      this.mapping.email_signature = item.props.email_signature || null
      this.mapping.groups = item.groups.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.mapping.leads = item.leads.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.search_items = item.leads
      this.mapping.iteration_items = item.iterations.map((i, index) => {
        return {
          step: index + 1,
          template: i.templatable_id,
          content_type: i.props.content_type,
          content: i.props.message,
          schedule: {
            startDate: this.moment(i.startDate).format('YYYY-MM-DD'),
            endDate: this.moment(i.endDate).format('YYYY-MM-DD')
          }
        }
      })
    },
    templateById(id) {
      if (!id) return 'Template not set!'
      let selected = this.templates.find((i) => i.id === id)
      return selected ? selected.name : 'Template not found!'
    },
    step1Rules() {
      let has_recipient =
        this.mapping.groups.length || this.mapping.leads.length ? true : false
      return this.mapping.name &&
        this.mapping.name.length &&
        this.mapping.subject &&
        this.mapping.subject.length &&
        has_recipient
        ? true
        : false
    },
    step2Rules() {
      if (!this.mapping.iteration_items.length) return false
      for (let i = 0; i < this.mapping.iteration_items.length; i++) {
        let iteration = this.mapping.iteration_items[i]
        if (
          (!iteration.template && !iteration.content) ||
          !iteration.schedule.startDate ||
          !iteration.schedule.endDate
        )
          return false
      }
      return true
    },
    step3Rules() {
      return this.step1Rules() && this.step2Rules()
    },
    addSignature() {
      this.$router.push({
        name: 'builders-email--signature'
      })
    },
    createNewTemplate() {
      this.$router.push({
        name: 'builders-email--template-campaign'
      })
    },
    goBack() {
      if (this.$route.query.redirect_uri) {
        this.$router.push({ path: this.$route.query.redirect_uri })
      } else {
        this.$router.go(-1)
      }
    },
    addNewIteration() {
      this.mapping.iteration_items.push({
        step: this.mapping.iteration_items.length + 1,
        content_type: 'plain',
        content: null,
        template: null,
        schedule: {
          startDate: null,
          endDate: null
        }
      })
    },
    handleUpdate(payload, index) {
      this.mapping.iteration_items.splice(index, 1, payload)
    },
    handleDelete(index) {
      this.mapping.iteration_items.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.campaign-creator {
  .v-stepper {
    box-shadow: none !important;

    .v-stepper__header {
      box-shadow: none !important;

      .v-stepper__step__step {
        padding: 15px;
        border-radius: 5px;
        font-size: 17px;
      }
    }
  }
}
</style>
